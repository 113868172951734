import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Col,Row } from 'reactstrap'
import {css} from 'emotion'

const NotFoundPage = () => (
  <Layout headerType='404' headerText={'404 Page Not Found'}>
    <Container className={css`
        margin-top: 30px;
      `}>
      <Row>
      <Col className={'col-12 col-lg-6 offset-lg-3 text-center'}>
        <SEO title="404: Not found" />
        <h1>Hmmm.... nothing here</h1>
        <p>Try checking out my homepage <a href="/">homepage</a>.</p>
      </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
